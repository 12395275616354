import {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import mata from './../../images/koduj.png';
import './IntroView.scss';
import { useDispatch } from 'react-redux';
import { switchContentUpdated } from '../../reducer';

export default function IntroView() {
  const dispatch = useDispatch();

  const getWindowSize = () => {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    dispatch(switchContentUpdated(false));

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
      <main>
        <div className="intro">
        <div className="intro__half">
          <h1>Witaj w aplikacji KodujMata!</h1>
          <p> Nasza aplikacja pozwoli Ci realizować różne scenariusze zabaw z kodowania dla dzieci w formie online i stanowi doskonałe uzupełnienie dużej maty tradycyjnej. Jak pracować na KodujMacie? Kliknij przycisk <strong>ZAGRAJ</strong></p><ul><li>Jeśli chcesz poznać przykłady zabaw z kodowania dla dzieci, wybierz przycisk <strong>Scenariusze zabaw </strong>i zapoznaj się z propozycjami aktywności na macie.</li><li>Jeśli chcesz samodzielnie układać elementy obrazkowe na planszy i tworzyć własne konfiguracje zabaw na ekranie, wybierz przycisk <strong>Układaj</strong>.</li></ul><p>Udanej zabawy!</p>
          
          {windowSize.innerWidth < 1200 ?
            <p className="warning">Do poprawnego działania aplikacji wymagany jest ekran o przekątnej większej niz 9 cali.</p>
          :
            <Link className="button" to="/choice">Zagraj</Link>
          }
        </div>
        <div className="intro__half">
          <img src={ mata } className="" alt="Koduj mata" />
        </div>
      </div>
    </main>
  );
}