import { useSelector } from 'react-redux';
import Menu from '../../components/Menu/Menu';
import './ScenariosView.scss';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default function ScenariosView() {
  
  const {activeScenario, activeCategory, scenarios} = useSelector((state) => state);

  const selectedScenario = scenarios && scenarios.filter((scenario) => {
    return scenario.slug == activeScenario;
  });

  const saveToPDF = (scenarioName) => {
    const divToSave = document.querySelector(".scenarios-view");
    let elementsToHide = document.querySelectorAll(".hide-in-pdf");
    document.querySelector(".scenarios-view").style.borderWidth = '0px';
    elementsToHide.forEach((val) => { val.style.display = 'none' });
    html2canvas(divToSave, {
      proxy: "/",
      useCORS: true,
    })
    .then((canvas) => {
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      let doc = new jsPDF('p', 'mm');
      let position = 0;
      const pageData = canvas.toDataURL('image/jpeg', 1.0);
      let imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      doc.save(`Scenariusz ${scenarioName}.pdf`);
    })
    elementsToHide.forEach((val) => { val.style.display = 'flex' });
    document.querySelector(".scenarios-view").style.borderWidth = '4px';
  }

  return (
    <main>
      <Menu />
      <div className={`view-content${activeScenario ? ' scenarios-view' : ' view-content--center'}`}>
      {
        activeScenario !== null && selectedScenario !== null && selectedScenario[0] ?
          <>
            <h1>{activeCategory} - {selectedScenario[0].name}</h1>
            {
            selectedScenario[0].image_url.length > 0 ?
              <>
                <h2>Propozycja podania:</h2>
                <img className="board-image" src={selectedScenario[0].image_url} alt={`Plansza ${selectedScenario[0].name}`} />
              </>
            :
              ''
            }
            {
            selectedScenario[0].video_url.length > 0 ?
              <video className="hide-in-pdf" controls>
                <source src={require('./../../videos/' + selectedScenario[0].video_url)} />
              </video>
            :
              ''
            }
            <div className="content" dangerouslySetInnerHTML={{ __html: selectedScenario[0].description }} />
            <div className="row row--3 hide-in-pdf">
              <button className='button button--white' onClick={() => saveToPDF(selectedScenario[0].name)}>Pobierz PDF</button>
              <a
                className="button button--white"
                href="/scenarios"
                target="_blank"
                rel="noopener noreferrer"
              >
                Otwórz w nowym oknie
              </a>
              <a
                className="button button--white"
                href="https://kodujmata.pl/sklep/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kup podręcznik
              </a>
            </div>
          </>
        :
          <h1>Rozwiń listę scenariusze i wybierz jedną z zabaw po lewej</h1>
      }
      </div>
    </main>
  );
}