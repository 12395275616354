import React  from 'react';
import Element from '../Element/Element';
import './Search.scss';

export default function Search({elements, activeElement}) {
  const [inputValue, setInputValue] = React.useState("");

  const onChangeHandler = event => {
    setInputValue(event.target.value);
  };

  const stringMatches = (element, value) => {
    return element.slug.includes(value);
  }

  let matchingElements = elements && elements.filter(element => stringMatches(element, inputValue));

  return (
    <div className="search">
      <input
        type="text"
        name="name"
        className="search__input"
        placeholder="Wyszukaj element"
        onChange={onChangeHandler}
        value={inputValue}
      />
      {inputValue != '' ?
        matchingElements.length > 0 ? 
          <ul className="search__elements" key={`list-elements`}>
            {matchingElements.map(element => (
              <Element
                key={`element-${element.slug}`}
                item={element}
                classModifier={activeElement == element ? ' menu__item--active' : ''}
              />
            ))}
          </ul>
        :
          <div className='search__info'>Nie ma takiego elementu</div>
      :
        ''
      }
    </div>
  );
}