import { useDispatch, useSelector } from 'react-redux';
import { boardUpdate, changeDragEnterPosition } from '../../reducer';
import './Square.scss';

const Square = ({n, m, value}) => {
  const dispatch = useDispatch();
  let {board, activeElement, dragEnterPosition} = useSelector((state) => state);

  const handleSquareClick = (n, m) => {
    const horizontalRow = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    const tempBoard = JSON.parse(JSON.stringify(board));

    if (board[n][m] && board[n][m].slug == activeElement.slug)
      if (n == 0)
        tempBoard[n][m] = m;
      else if (m == 0)
        tempBoard[n][m] = horizontalRow[n-1];
      else {
        tempBoard[n][m] = null;
      }
    else
      tempBoard[n][m] = activeElement;

    dispatch(boardUpdate(tempBoard));
  }

  const handleKeyPress = (e, n, m) => {
    if (e.charCode == 13)
      handleSquareClick(n, m);
  }

  const dragEnter = (e) => {
    const target = e.target.getAttribute("data-index-n") ? e.target : e.target.parentElement;
    
    dispatch(changeDragEnterPosition([target.getAttribute("data-index-n"), target.getAttribute("data-index-m")]))
  };

  const drop = (e) => {
    const target = e.target.getAttribute("data-index-n") ? e.target : e.target.parentElement;
    let tempBoard = JSON.parse(JSON.stringify(board));
    tempBoard[dragEnterPosition[0]][dragEnterPosition[1]] = activeElement;

    if(target.className == "square")
      dispatch(boardUpdate(tempBoard));
  };
  
  return(
    <div
      className="square"
      tabIndex="0"
      data-index-n={n}
      data-index-m={m}
      onKeyPress={(e) => handleKeyPress(e, n, m)}
      onClick={() => handleSquareClick(n, m)}
      onDragEnter={(e) => dragEnter(e)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => drop(e)}
    >
      { typeof value === 'object' && value !== null ?
        <img src={value.url} title={value.name} alt={value.name} />
      :
        value
      }
    </div>
  )
};

export default Square;
