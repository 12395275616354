import { useDispatch } from 'react-redux';
import { elementChange } from '../../reducer';
import './Element.scss';


export default function Element({item, classModifier}) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(elementChange(item));
  }

  const handleKeyPress = (e) => {
    if (e.charCode == 13)
      dispatch(elementChange(item));
  }

  const dragStart = () => {
    dispatch(elementChange(item));
  };

  return (
    <li
      className={`menu__item ${classModifier ? classModifier : ''}`}
      onClick={(e) => handleClick(e, item.slug)}
      onKeyPress={(e) => handleKeyPress(e)} 
      tabIndex="0"
      onDragStart={() => dragStart()}
      draggable
    >
      <img
        src={item.url}
        title={item.name}
        alt={item.name}
        data-item={item.slug}
      />
    </li>
  );
}