import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { groupChange } from '../../reducer';

export default function GroupsChoiceView() {
  const dispatch = useDispatch();

  return (
    <main className="view-content groups-view">
      <h1>Wybierz grupę</h1>
      <div className="row">
        <Link className="button button--high button--capitalize" to="/board-size" onClick={() => dispatch(groupChange('przedszkole'))}>Edukacja przedszkolna</Link>
        <Link className="button button--high button--capitalize" to="/board-size" onClick={() => dispatch(groupChange('szkola'))}>Edukacja wczesnoszkolna</Link>
        <div className="flex-break"></div>
        <Link className="button button--high button--capitalize" to="/board-size" onClick={() => dispatch(groupChange('autyzm'))}>Autyzm</Link>
        <Link className="button button--high button--capitalize" to="/board-size" onClick={() => dispatch(groupChange('wzrok'))}>Dzieci słabowidzące</Link>
      </div>
    </main>
  );
}