import { useSelector } from 'react-redux';
import Square from '../Square/Square';
import './Board.scss';

export default function Board() {
  let { board } = useSelector((state) => state);

  return (
    <div className="board__container">
      <div className="board__XRow board__XRow--margin-bottom">
        {board.map((col, i) => i > 0 && i <= board.length - 3 ? <Square key={`horizontalRow-${i}`} n={i} m={0} value={col[0]} board={board} /> : '')}
      </div>
      <div className="board__align">
        <div className="board__YRow">
          {board[0].map((row, i) => i > 0 && i <= board.length - 3 ? <Square key={`vericalRow-${i}`} n={0} m={i} value={row} board={board} /> : '' )}
        </div>
        <table className="board">
          <tbody>
            {
              board.map((row, n) => (
                n > 0 && n <= board.length - 3 ?
                  <tr key={`tr-${n}`}>
                    {
                      row.map((item, m) => (
                        m > 0 && m <= board.length - 3 ?
                          <td key={`td-${n}${m}`}>
                            <Square key={`square-${n}${m}`} board={board} n={n} m={m} value={item} />
                          </td>
                        :
                          ''
                      ))
                    }
                  </tr>
                :
                  ''
              ))
            }
          </tbody>
        </table>
      </div>
      <div className="board__XRow board__XRow--margin-top">
        {board.map((col, i) => i > 0 && i <= board.length - 3 ? <Square key={`horizontalRow-${i+1}`} n={i} m={board.length-2} value={col[board.length-2]} board={board} /> : '')}
      </div>
      <div className="board__XRow">
        {board.map((col, i) => i > 0 && i <= board.length - 3 ? <Square key={`horizontalRow-${i+2}`} n={i} m={board.length-1} value={col[board.length-1]} board={board} /> : '')}
      </div>
    </div>
  );
}