import React from 'react';
import { Link } from "react-router-dom";
import './Header.scss';
import FontSizeChanger from './../FontSizeChanger/FontSizeChanger';
import ContrastToggler from '../ContrastToggler/ContrastToggler';
import logo from './../../images/koduj-mata.png';
import logoContrast from './../../images/koduj-mata-contrast.png';

const Header = ({ contrast, handleIncrementClick, handleDecrementClick }) => (
  <header className="header">
    <Link to="/">
      <img src={ contrast ? logoContrast : logo } className="header__logo" alt="logo" />
    </Link>
    <div className="header__right-bar">
      <FontSizeChanger />
      <ContrastToggler contrast={ contrast } />
    </div>
  </header>
);

export default Header;
