import { useDispatch } from 'react-redux';
import { fontSizeChanger } from '../../reducer';
import './FontSizeChanger.scss';

const FontSizeChanger = () => {
  const dispatch = useDispatch();

  return (
    <div className="fc">
      <button className="fc__button fc__button--minus" title="Decrease font size" onClick={() => dispatch(fontSizeChanger('DECREMENT'))}></button>
      <span>A</span>
      <button className="fc__button fc__button--plus" title="Increase font size" onClick={() => dispatch(fontSizeChanger('INCREMENT'))}></button>
    </div>
  )
};

export default FontSizeChanger;