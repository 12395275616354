import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'global',
  initialState: {
    contrast: false,
    fontSize: 1,
    activeFlow: null,
    activeGroup: null,
    activeCategory: null,
    activeScenario: null,
    activeElement: null,
    selectedScenario: null,
    board: null,
    dragEnterPosition: null,
    contentUpdated: false,
    scenarios: null,
    categories: null,
    elements: null
  },
  reducers: {
    contrastToggle: (state, action) => {
      state.contrast = !state.contrast
    },
    fontSizeChanger: (state, action) => {
      switch (action.payload) {
        case "INCREMENT":
          state.fontSize += 0.1;
          break;
        case "DECREMENT":
          state.fontSize >= 1.1 ? state.fontSize = state.fontSize - 0.1 : state.fontSize = state.fontSize;
          break;
        default:
          state.fontSize;
      }
    },
    flowChange: (state, action) => {
      state.activeFlow = action.payload
    },
    groupChange: (state, action) => {
      state.activeGroup = action.payload
    },
    categoryChange: (state, action) => {
      state.activeCategory = action.payload
    },
    scenarioChange: (state, action) => {
      state.activeScenario = action.payload
    },
    elementChange: (state, action) => {
      if (state.activeElement == action.payload)
        state.activeElement = null
      else
        state.activeElement = action.payload
    },
    boardUpdate: (state, action) => {
      state.board = action.payload
    },
    changeDragEnterPosition: (state, action) => {
      state.dragEnterPosition = action.payload
    },
    switchContentUpdated: (state, action) => {
      state.contentUpdated = action.payload
    },
    updateScenarios: (state, action) => {
      state.scenarios = action.payload
    },
    updateCategories: (state, action) => {
      state.categories = action.payload
    },
    updateElements: (state, action) => {
      state.elements = action.payload
    }
  },
})

export const { contrastToggle, fontSizeChanger, flowChange, groupChange, categoryChange, scenarioChange, elementChange, boardUpdate, changeDragEnterPosition, switchContentUpdated, updateScenarios, updateCategories, updateElements } = menuSlice.actions

export default menuSlice.reducer
