import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from '../../images/arrow-down.svg';
import { scenarioChange, categoryChange, flowChange } from '../../reducer';

export default function MenuItem({item, subcategory, classModifier = '', arrow, chooseOption}) {
  const dispatch = useDispatch();
  const {activeFlow, activeScenario} = useSelector((state) => state);
  const [isListVisible, changeListVisibility] = React.useState("");

  const handleMenuClick = (e) => {
    !chooseOption ? e.preventDefault() : '';

    changeListVisibility(!isListVisible);

    if (chooseOption == 'category') {
      dispatch(scenarioChange(item.slug));
      dispatch(categoryChange(subcategory));
      dispatch(flowChange('scenarios'));
    }

    if (chooseOption == 'scenarios' && activeFlow == 'play') {
      dispatch(scenarioChange(null));
      dispatch(categoryChange(null));
      dispatch(flowChange('scenarios'));
    }
  }

  return (
    <Link
      to={activeFlow == 'play' ? '/group-choice' : '/scenarios'}
      className={`menu__link${' ' + classModifier} ${isListVisible ? 'menu__link--list-visible' : 'menu__link--list-hidden'}${activeScenario == item.slug ? ' menu__link--active' : ''}`}
      onClick={(e) => handleMenuClick(e)}
    >
      {item.name}
      {arrow ? <ArrowIcon/> : ''}
    </Link>
  );
}