import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route 
} from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './configure-store';
import App from './App';
import IntroView from './views/IntroView/IntroView';
import ChoiceView from './views/ChoiceView/ChoiceView';
import EmptyView from './views/EmptyView/EmptyView';
import BoardSizeChoiceView from './views/BoardSizeChoiceView/BoardSizeChoiceView';
import GroupsChoiceView from './views/GroupsChoiceView/GroupsChoiceView';
import ScenariosView from './views/ScenariosView/ScenariosView';
import PlaygroundView from './views/PlaygroundView/PlaygroundView';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={ store }>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<IntroView />} />
            <Route path="choice" element={<ChoiceView />} />
            <Route path="board-size" element={<BoardSizeChoiceView />} />
            <Route path="group-choice" element={<GroupsChoiceView />} />
            <Route path="scenarios" element={<ScenariosView />} />
            <Route path="playground" element={<PlaygroundView />} />
            <Route path="*" element={<EmptyView />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();