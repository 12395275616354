
import { Link } from "react-router-dom";
import './EmptyView.scss';

export default function EmptyView() {
  return (
    <main className="view-content empty-view">
      <div>
        <h1>Nic tu nie ma</h1>
        <Link className="button" to="/">Wróć do strony głównej</Link>
      </div>
    </main>
  );
}