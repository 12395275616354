import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { boardUpdate } from '../../reducer';

export default function BoardSizeChoiceView() {
  const dispatch = useDispatch();
  const {board, activeFlow} = useSelector((state) => state);
  let flow = activeFlow == 'scenarios' ? '/scenarios' : '/playground';
  let tempBoard;

  const createBoard = (boardSize) => {
    if (board && boardSize == board.length )
      tempBoard = board;
    else {
      tempBoard = [...Array(boardSize).fill(null)].map(
        () => Array(boardSize).fill(null)
      );
      tempBoard = fillFirstRowAndCol(tempBoard);
    }

    dispatch(boardUpdate(tempBoard));
  }

  const fillFirstRowAndCol = (board) => {
    const horizontalRow = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

    board.forEach((element, n) => {
      if (n > 0)
        board[n][0] = horizontalRow[n-1];
        board[0][n] = n;
    })

    return board;
  }
  
  return (
    <main className="view-content board-size-view">
      <h1>Wybierz liczbę pól na macie:</h1>
      <div className="row row--3">
        <Link className="button button--high" to={flow} onClick={() => createBoard(7)}>4x4</Link>
        <Link className="button button--high" to={flow} onClick={() => createBoard(11)}>8x8</Link>
        <Link className="button button--high" to={flow} onClick={() =>  createBoard(13)}>10x10</Link>
      </div>
    </main>
  );
}