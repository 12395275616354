import { useDispatch, useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Menu from '../../components/Menu/Menu';
import Board from '../../components/Board/Board';
import { boardUpdate } from '../../reducer';

export default function PlaygroundView() {
  const dispatch = useDispatch();
  const {board} = useSelector((state) => state);

  const resetBoard = (board) => {
    board = [...Array(board.length).fill(null)].map(
      () => Array(board.length).fill(null)
    );
    board = fillFirstRowAndCol(board);

    dispatch(boardUpdate(board));
  }

  const fillFirstRowAndCol = (board) => {
    const horizontalRow = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

    board.forEach((element, n) => {
      if (n > 0)
        board[n][0] = horizontalRow[n-1];
        board[0][n] = n;
    })

    return board;
  }

  const saveToPDF = () => {
    const divToSave = document.querySelector(".board__container");
    let elementsToHide = document.querySelectorAll(".hide-in-pdf");
    elementsToHide.forEach((val) => { val.style.display = 'none' });

    html2canvas(divToSave).then((canvas) => {
      const imgWidth = 190;
      const pageHeight = 290;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      let doc = new jsPDF('p', 'mm');
      let position = 10;
      const pageData = canvas.toDataURL('image/jpeg', 1.0);
      let imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      doc.save(`Plansza.pdf`);
    })

    elementsToHide.forEach((val) => { val.style.display = 'flex' });
  }

  // const keyPressHandle = ({ key }) => {
  //   if (key == 'p' && document.querySelector(".board__XRow > .square"))
  //     document.querySelector(".board__XRow > .square").focus();
  //   if (key == 'm' && document.querySelector(".menu__item--active"))
  //     document.querySelector(".menu__item--active").focus()
  //   else if (key == 'm' && document.querySelector(".menu__elements-list > li > a"))
  //     document.querySelector(".menu__elements-list > li > a").focus() 
  // }

  // window.addEventListener("keydown", keyPressHandle)

  return (
    <main>
      <Menu />
      <div className={`view-content playground-view`}>
        <Board />
        <div className="row row--3 hide-in-pdf">
          <button className='button button--white' onClick={() => resetBoard(board)}>Wyczyść planszę</button>
          <button className='button button--white' onClick={() => saveToPDF()}>Pobierz PDF</button>
        </div>
      </div>
    </main>
  );
}

