import { useDispatch, useSelector } from 'react-redux';
import './ContrastToggler.scss';
import { contrastToggle } from '../../reducer';

const ContrastToggler = () => {
  const dispatch = useDispatch();
  let { contrast } = useSelector((state) => state);

  return(
    <div className="ct">
      {!contrast ? <span className="ct__value ct__value--off">off</span> : ''}
      <label className="ct__switch">
        <input className="ct__input" type="checkbox" onChange={() => dispatch(contrastToggle())} ></input>
        <span className={`ct__slider ct__slider--${contrast}`}></span>
      </label>
      {contrast ? <span className="ct__value ct__value--on">on</span> : ''}
    </div>
  )
};

export default ContrastToggler;