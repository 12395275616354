import React  from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";
import '../src/styles/_global.scss';
import Header from '../src/components/Header/Header';
import Footer from '../src/components/Footer/Footer';

const App = () => {
  const state = React.useState(0);
  let { contrast, fontSize } = useSelector((state) => state);

  return (
    <div className={`app-wrapper${contrast ? ' contrast' : ''}`}>
      <div className="app">
        <style>
          {`
          html { 
            font-size: ${62.5 * fontSize}%;
          }
          `}
        </style>
        <Header/>
        <Outlet context={state} />
        <Footer />
      </div>
    </div>
  );
}

export default App;