import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { flowChange } from '../../reducer';

export default function ChoiceView() {
  const dispatch = useDispatch();

  return (
    <main className="view-content choice-view">
      <h1>Poznaj propozycje gotowych aktywności na planszy (Scenariusze zabaw) lub twórz własne ćwiczenia (Układaj):</h1>
      <div className="row">
        <Link className="button button--high" to="/group-choice" onClick={() => dispatch(flowChange('scenarios'))}>Scenariusze Zabaw</Link>
        <Link className="button button--high" to="/board-size" onClick={() => dispatch(flowChange('play'))}>Układaj</Link>
      </div>
    </main>
  );
}